<template>
<div>
    <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
        <b-col sm>
            <div>
                <button class="btn btn-success" type="button" @click="exportTable">Export</button>
            </div>
        </b-col>
    </b-row>
    <b-row class="mb-3">
        <b-col sm>
            <div>
                <b-form-group
                    class="mb-3"
                    label="Search :"
                    label-for="search"
                    description="Searchable : Title"
                >
                    <b-input-group
                    >
                        <b-form-input
                            id="search"
                            type="search"
                            v-model="filterText"
                            placeholder="Title ..."
                            @keypress="doFilter"
                            @keyup.delete="doFilter"
                        >
                        </b-form-input>
                        <b-input-group-append
                        >
                            <b-button
                                variant="secondary" @click="resetFilter"
                                type="button"
                            >
                                Reset
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </b-col>
        <b-col sm>
            <div>
                <b-form-group
                    class="mb-3"
                    label="Date :"
                    label-for="daterange"
                >
                    <b-input-group
                    >
                        <b-input-group-prepend is-text>
                            <i class="fa fa-calendar"></i>
                        </b-input-group-prepend>
                        <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
                        <b-input-group-append>
                            <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <vuetable ref="vuetable"
    @vuetable:load-error="handleLoadError"
    :api-url="apiUrl"
    :http-options="HttpOptions"
    :fields="fields"
    pagination-path=""
    :muti-sort="true"
    :sort-order="sortOrder"
    :append-params="moreParams"
    @vuetable:pagination-data="onPaginationData">

        <template slot="name-slot" slot-scope="prop">
          <span>{{ prop.rowData.name }}</span>
        </template>

        <template slot="tax-slot" slot-scope="prop">
            <span>{{ parseInt(prop.rowData.tax) | currency }}</span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
            <div class="custom-actions">
                <button  class="btn btn-danger" @click="onAction('delete-item', prop.rowData)"><i class="fa fa-trash-o"></i></button>
            </div>
        </template>
    </vuetable>

    <div class="vuetable-pagination ui basic segment grid">
    <vuetable-pagination-info ref="paginationInfo"
    ></vuetable-pagination-info>

    <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
    ></vuetable-pagination>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

let startDate = '';
let endDate = '';
Vue.use(Vuetable);

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            isLoading: false,
            filterText: '',
            dateDetail: {
            start_from: '',
            end_to: ''
            },
            apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `taxes`,
            HttpOptions: {
                headers: {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
                }
            },
            sortOrder: [
                {
                    field: 'created_at',
                    sortField: 'created_at',
                    direction: 'desc'
                }
            ],
            moreParams: {},
            fields: [
                {
                    name: 'name-slot',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'tax-slot',
                    sortField: 'tax',
                    title: 'Tax'
                },
                {
                    name: 'actions-slot',
                    title: 'Actions',
                    titleClass: 'center aligned',
                    dataClass: 'center aligned',
                    width: '20%'
                }
            ]
        }
    },
    methods: {
        doFilter () {
            this.$events.$emit('filter-set', this.filterText)
        },
        resetFilter () {
            this.filterText = ''  // clear the text in text input
            this.$events.$emit('filter-reset')
        },
        exportTable() {
            this.isLoading = true
            if (this.startDate != '' && this.endDate != '') {
                startDate = this.startDate;
                endDate = this.endDate;
            }else{
                endDate = this.$setDate.format('YYYY-MM-DD');
                startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
            }
            this.$http.get(`tax/` + startDate + `/` + endDate)
            .then((result) => {
            this.isLoading = false
            const  exportPath = result.data.meta.data;
            window.location.href = exportPath;
            }).catch((error) => {
            if (error.response) {
                this.isLoading = false
                this.errors.code = error.response.status;
                this.errors.status = error.response.data.meta.code;
                this.errors.headers = error.response.headers;
                }
            })
        },
        handleLoadError(error) {
            this.errors.status = error.response.data.meta.code;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.code == 401) {
                if (localStorage.getItem('access_token') != null) {
                    localStorage.removeItem('access_token');
                    this.$swal.fire(
                        'Your session expired!',
                        'Your session has expired. Please login again to access this page!',
                        'error'
                    ).then(() =>{
                        this.$router.push("/login")
                    })
                }
            }else if(this.errors.code == 403) {
                this.$router.push("/403")
            }else if(this.errors.code == 500) {
                this.$router.push("/500")
            }
        },
        onAction(action, data) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this Tax!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then((result) => {
                if (result.value) {
                    this.$http.delete(`tax-delete/` + data.id)
                    .then(() => {
                    this.$swal.fire(
                        'Deleted!',
                        'Tax has been deleted.',
                        'success'
                    ).then(() => {
                        location.reload();
                    })
                    }).catch((error) => {
                        if (error.response) {
                            this.errors.code = error.response.status;
                            this.errors.message = error.response.data.meta.message;
                            this.errors.status = error.response.data.meta.code;
                        }
                    })
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                    this.$swal.fire(
                    'Cancelled',
                    'Tax was successfully canceled for deletion!',
                    'error'
                    )
                }
            })
        },
        resetDateFilter () {
            this.startDate = ''
            this.endDate = ''
            this.$events.$emit('date-filter-reset')
        },
        doDateFilter(value){
            this.startDate = value.startDate;
            this.endDate = value.endDate;
            this.$events.fire('date-set', [this.startDate, this.endDate])
        },
        onDateSet()  {
            this.start_from = this.startDate;
            this.end_to = this.endDate;
            this.moreParams = {
                'start_from': this.start_from,
                'end_to': this.end_to
            }
            Vue.nextTick( () => this.$refs.vuetable.refresh())
        },
        onChangePage (page) {
            this.$refs.vuetable.changePage(page)
        },
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
            this.$refs.paginationInfo.setPaginationData(paginationData)
        },
        onFilterSet (filterText) {
            this.moreParams = {
                'filter': filterText
            }
            Vue.nextTick( () => this.$refs.vuetable.refresh())
        },
        onFilterReset () {
            this.moreParams = {}
            Vue.nextTick( () => this.$refs.vuetable.refresh())
        },
        onDateFilterReset () {
            this.moreParams = {}
            Vue.nextTick( () => this.$refs.vuetable.refresh())
        }
    },
    mounted() {
    this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
    this.$events.$on('filter-reset', () => this.onFilterReset())
    this.$events.$on('date-set', eventData => this.onDateSet(eventData))
    this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
}
</script>
<style>
/* Absolute Center Spinner */
.loading {
position: fixed;
z-index: 999;
height: 2em;
width: 2em;
overflow: visible;
margin: auto;
top: 0;
left: 0;
bottom: 0;
right: 0;
}

/* Transparent Overlay */
.loading:before {
content: '';
display: block;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
